export const environment = {
  production: false,
  staging: true,
  sentryEnvironment: 'staging',
  backendUrl: 'https://api.stage.member.ukcreditratings.com',
  checkoutUrl: 'https://checkout.ukcreditratings.com',

  // This is set during the build process, using replace-in-file package.  See pipelines for usage.
  release: 'portal@2546',

  mixpanelKey: '',
  clarityHeatmapProject: '',

  // ThreeDS
  paayFormId: 'deferred-auth-card-form',
  paayApiKey: 'dec744525381db3c9eee62166d10e8ed'
};
